import React from "react";
import styles from "./ImageContainer.module.scss";
import { RouteLinks } from "../../../../utils/route-links";
import { Link } from "gatsby";
import cx from "classnames";

interface Props {
  image: string;
  text: string;
  imageFit?: "contain" | "cover";
  externalLink: string;
}

const ImageContainer: React.FC<Props> = (props: Props) => {
  const { imageFit } = props;

  return (
    <article className={cx("PDImageContainer", styles.container)}>
      <div className={cx("column", styles.column)}>
        <figure className={styles.image}>
          <img
            src={props.image}
            alt="Showcase clicks"
            style={{
              objectFit: imageFit,
            }}
          />
        </figure>
        {!!props.text || !!props.externalLink ? (
          <section className={styles.text}>
            {!!props.text ? <p className="d9">{props.text}</p> : null}
            {props.externalLink ? (
              <Link to={RouteLinks.index} className="d9">
                View Story
              </Link>
            ) : null}
          </section>
        ) : null}
      </div>
    </article>
  );
};

ImageContainer.defaultProps = {
  imageFit: "cover",
};

export default ImageContainer;
