import React, { useState, useRef, useEffect } from "react";
import styles from "./portfoliosTemplate.module.scss";
import cx from "classnames";
import { useMediaQuery } from "react-responsive";
import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import "scroll-behavior-polyfill";

import FindPackage from "../../homepage/find-package";
import LinkButton from "../../link-button/LinkButton";
import RightArrowButton from "../../../commons/curve-arrow-button/RightArrowButton";
import Layout from "../../layout/Layout";
import ImageContainer from "../../portfolio-detail/image-container/ImageContainer";
import SEO from "../../../commons/seo";
import SVGContainer from "../../../commons/svg-container/SVGContainer";
import LeftArrowIcon from "../../../../images/icons/arrow-left.inline.svg";
import RightArrowIcon from "../../../../images/icons/arrow-right.inline.svg";
import ChevronUpIcon from "../../../../images/icons/chevron-up.inline.svg";
import { GqlListResponse } from "../../../../models/gql-list-response.model";
import { Portfolio } from "../../../../models/portfolio.model";
import { SeoRaw } from "../../../../models/seo.model";
import { smallScreenQuery } from "../../../../utils/utils";
import {
  getImagePreviewLink,
  getPackagesLink,
  getPortfolioLink,
  getPortfolioLinkFromSlug,
} from "../../../../utils/link.helper";
import { StringUtil } from "../../../../utils/string.util";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { IconSizes } from "../../../../enums/icon-sizes";
import { Video } from "../../../../models/video";
import VideoContainer from "../../portfolio-detail/video-container";

const query = graphql`
  {
    portfolios: allMarkdownRemark(
      filter: { fields: { category: { eq: "portfolios" } } }
      sort: { order: ASC, fields: [fields___relativePath] }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
          }
        }
      }
    }
  }
`;

const PortfoliosDetail = (props: any) => {
  const [showBtn, setShowBtn] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  //For Desktop
  const [translateValue, setTranslateValue] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const matches = useMediaQuery({ query: smallScreenQuery });

  const queryResult = useStaticQuery(query);

  useEffect(() => {
    const timer = setTimeout(() => {
      const location = window.location.href.split("/");
      const id = location[location.length - 2];
      document.getElementById(id)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  // Scroll to previous clicked image
  useEffect(() => {
    const previousSelectedImage = props.location?.state?.index;
    if (previousSelectedImage) {
      setTimeout(() => {
        // For mobile
        const previousImage = document.getElementById(
          `MediaContainer${previousSelectedImage}`
        );
        const position = previousImage!.getBoundingClientRect();

        const scrollableContainer = headerRef!.current;
        scrollableContainer!.scrollTo({
          top: position.top - 100,
          left: position.left,
          behavior: "smooth",
        });

        // For desktop
        setCurrentIndex(previousSelectedImage);
        setTranslateValue(
          -slideWidth(previousSelectedImage) * previousSelectedImage
        );
      }, 100);
    }
  }, []);

  const portfoliosRaw: GqlListResponse<Portfolio> = queryResult.portfolios;
  const portfolios = portfoliosRaw.edges;

  const {
    imagesMeta: currentPortfolioImages,
    slug: currentPortfolioSlug,
    first_package: currentPortfolioFirstLocation,
    seo: seo,
    title: currentPortfolioTitle,
    video: currentPortfolioVideos,
  }: {
    imagesMeta: {
      url: string;
      caption: string;
      link: string;
    }[];
    slug: string;
    title: string;
    video: Video[];
    first_package?: string;
    seo: SeoRaw;
  } = props.pageContext;

  let externalLink: string = "";
  let externalLinkDesc: string = "";

  if (currentPortfolioImages.length > 0) {
    if (currentPortfolioVideos) {
      if (currentIndex >= currentPortfolioVideos.length) {
        externalLink =
          currentPortfolioImages[currentIndex - currentPortfolioVideos.length]
            .link;
        externalLinkDesc =
          currentPortfolioImages[currentIndex - currentPortfolioVideos.length]
            .caption;
      }
    } else {
      externalLink = currentPortfolioImages[currentIndex].link;
      externalLinkDesc = currentPortfolioImages[currentIndex].caption;
    }
  }

  const handleScroll = (event: any) => {
    const { scrollTop, clientHeight } = event.target;

    if (matches) {
      const portfolioSection = document.getElementById("portfolios");
      if (
        !showBtn &&
        scrollTop > 150 &&
        scrollTop < portfolioSection!.scrollHeight - clientHeight
      ) {
        setShowBtn(true);
      } else if (
        showBtn &&
        scrollTop > portfolioSection!.scrollHeight - clientHeight
      ) {
        setShowBtn(false);
      } else if (showBtn && scrollTop < 150) {
        setShowBtn(false);
      }

      if (!showScrollToTop && scrollTop > 150) {
        setShowScrollToTop(true);
      } else if (showScrollToTop && scrollTop < 150) {
        setShowScrollToTop(false);
      }
    } else {
      if (showScrollToTop) {
        setShowScrollToTop(false);
      }
    }
  };

  const handleImageSelection = (index: number) => {
    navigate(
      getImagePreviewLink(
        currentPortfolioSlug,
        index,
        StringUtil.getFileNameAsURL(currentPortfolioImages[index].url)
      ),
      {
        state: {
          index,
        },
      }
    );
  };

  const slideWidth = (index: number) => {
    return document
      .querySelector(`#MediaContainer${index}`)!
      .getBoundingClientRect().width;
  };

  const getTotalMediaLength = () => {
    if (currentPortfolioVideos) {
      return currentPortfolioVideos.length + currentPortfolioImages.length;
    } else {
      return currentPortfolioImages.length;
    }
  };

  const goToPreviousSlide = () => {
    const totalLength = getTotalMediaLength();

    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      setTranslateValue(
        translateValue - (totalLength - 1) * slideWidth(currentIndex)
      );
    } else {
      setCurrentIndex(currentIndex - 1);
      setTranslateValue(translateValue + slideWidth(currentIndex));
    }
  };

  const goToNextSlide = () => {
    const totalLength = getTotalMediaLength();

    if (currentIndex === totalLength - 1) {
      setCurrentIndex(0);
      setTranslateValue(0);
    } else {
      setCurrentIndex(currentIndex + 1);
      setTranslateValue(translateValue - slideWidth(currentIndex));
    }
  };

  const handleScrollToTop = () => {
    const container = headerRef!.current;
    container!.scrollTo({ top: 0, behavior: "smooth" });
  };

  const listItems = (desktop?: boolean) => {
    return portfolios.map(
      ({
        node: portfolio,
        node: {
          frontmatter: { slug, title },
        },
      }) => {
        const _isCurrentPortfolio = slug === currentPortfolioSlug;
        const className = cx(
          "column",
          styles.listItem,
          _isCurrentPortfolio && styles.listItem__selected
        );
        return (
          <li className={className} key={slug} id={slug}>
            <Link
              to={getPortfolioLink(portfolio)}
              className="link"
              data-test-e2e={`${portfolio.frontmatter.slug}-${
                desktop ? "desktop" : "mobile"
              }`}
            >
              <p>{title}</p>
            </Link>
            {_isCurrentPortfolio && currentPortfolioFirstLocation && (
              <RightArrowButton
                className={styles.listItem__packageLink}
                text="See Packages"
                route={getPackagesLink(
                  currentPortfolioSlug,
                  currentPortfolioFirstLocation
                )}
                iconSize={IconSizes.IC__locusBtn}
                dataTestE2E={`${currentPortfolioSlug}-${currentPortfolioFirstLocation}-${
                  desktop ? "desktop" : "mobile"
                }`}
              />
            )}
            <div />
          </li>
        );
      }
    );
  };

  return (
    <Layout handleScroll={handleScroll} reference={headerRef}>
      {seo && (
        <SEO
          title={seo.metaTitle}
          description={seo.metaDescription}
          keywords={seo.metaKeywordList}
          imageUrl={
            !!seo.metaImageName ? `/previews/${seo.metaImageName}` : undefined
          }
          pageUrl={getPortfolioLinkFromSlug(currentPortfolioSlug)}
        />
      )}
      <main
        className="column"
        id="container"
        data-test-e2e={`${currentPortfolioSlug}-container`}
      >
        <section className={cx("column", styles.content)}>
          <ul className={styles.navLinks}>{listItems()}</ul>
          <section className={styles.portfolios} id="portfolios">
            <section className={cx("column", styles.mainContent)}>
              <div className={cx("column", styles.slider)}>
                <div className={cx(styles.iconBg, styles.iconBg__left)}>
                  <SVGContainer
                    iconSize={IconSizes.IC__35}
                    onClick={e => {
                      e.stopPropagation();
                      goToPreviousSlide();
                    }}
                    className={cx(styles.arrow, styles.arrow__normal)}
                  >
                    <IoIosArrowBack />
                  </SVGContainer>
                </div>
                <div
                  className={styles.sliderWrapper}
                  style={{
                    transform: matches ? "" : `translateX(${translateValue}px)`,
                    transition: matches ? "" : "transform ease-out 0.8s",
                  }}
                >
                  {currentPortfolioVideos?.map((video, index) => (
                    <VideoContainer
                      key={index}
                      title={video.title}
                      src={video.src}
                    />
                  ))}
                  {currentPortfolioImages.map((image, index) => (
                    <ImageContainer
                      key={index}
                      index={index}
                      imageSrc={image.url}
                      text={image.caption}
                      onClick={() => handleImageSelection(index)}
                      externalLink={externalLink}
                    />
                  ))}
                </div>
                <div className={cx(styles.iconBg, styles.iconBg__right)}>
                  <SVGContainer
                    iconSize={IconSizes.IC__35}
                    onClick={e => {
                      e.stopPropagation();
                      goToNextSlide();
                    }}
                    className={cx(styles.arrow, styles.arrow__normal)}
                  >
                    <IoIosArrowForward />
                  </SVGContainer>
                </div>
              </div>
              <div className={styles.switch}>
                {currentPortfolioFirstLocation ? (
                  <LinkButton
                    route={getPackagesLink(
                      currentPortfolioSlug,
                      currentPortfolioFirstLocation
                    )}
                    className={styles.seePackage}
                  >
                    See Packages
                  </LinkButton>
                ) : (
                  <div />
                )}
                <section className={styles.switch__navigation}>
                  <SVGContainer onClick={goToPreviousSlide}>
                    <LeftArrowIcon />
                  </SVGContainer>
                  &nbsp; &nbsp;
                  <SVGContainer onClick={goToNextSlide}>
                    <RightArrowIcon />
                  </SVGContainer>
                </section>
              </div>
            </section>
            <ul className={cx("column", styles.links)}>{listItems(true)}</ul>
          </section>
        </section>
        <FindPackage
          categoryInitialValue={currentPortfolioSlug}
          locationInitialValue={currentPortfolioFirstLocation}
        />
        {showBtn && currentPortfolioFirstLocation && (
          <div className={styles.packagesBtn}>
            <LinkButton
              route={getPackagesLink(
                currentPortfolioSlug,
                currentPortfolioFirstLocation
              )}
            >
              See Packages
            </LinkButton>
          </div>
        )}
        {showScrollToTop && (
          <div className={styles.scrollToTop} onClick={handleScrollToTop}>
            <SVGContainer iconSize={IconSizes.IC__default}>
              <ChevronUpIcon />
            </SVGContainer>
          </div>
        )}
      </main>
    </Layout>
  );
};

export default PortfoliosDetail;
