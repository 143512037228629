import React from "react";
import PortfolioTemplate1 from "../components/v1/templates/portfolioTemplate";
import PortfolioTemplate2 from "../components/v2/templates/portfolioTemplate";

const PortfolioTemplate = (props: any) => {
  if (process.env.GATSBY_VERSION === "v1") {
    return <PortfolioTemplate1 {...props} />;
  } else {
    return <PortfolioTemplate2 {...props} />;
  }
};

export default PortfolioTemplate;
