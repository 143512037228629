import React, { useEffect } from "react";
import styles from "./VideoContainer.module.scss";

interface Props {
  src: string;
  title: string;
}

const VideoContainer: React.FC<Props> = (props: Props) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://player.vimeo.com/api/player.js";
    script.type = "text/javascript";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div id="MediaContainer" className={styles.container}>
      <iframe
        src={props.src}
        title={props.title}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
};

export default VideoContainer;
