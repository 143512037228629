import React from "react";
import styles from "./ImageContainer.module.scss";
import { StringUtil } from "../../../../utils/string.util";

interface Props {
  index: number;
  onClick: () => void;
  imageSrc: string;
  text: string;
  externalLink: string;
}

const ImageContainer: React.FC<Props> = (props: Props) => {
  const { onClick, imageSrc, text, externalLink, index } = props;

  const imageFileName = StringUtil.getFileNameFromURL(imageSrc);

  return (
    <figure
      onClick={onClick}
      id={`MediaContainer${index}`}
      className={styles.ImageContainer}
    >
      <img src={imageSrc} alt={text ?? imageFileName} />
      {text && <figcaption>{text}</figcaption>}
    </figure>
  );
};

export default ImageContainer;
